$sizings: (
  "0": 0,
  "025": 0.25,
  "05": 0.5,
  "075": 0.75,
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
);

@each $name, $size in $sizings {
  .p-#{$name} {
    padding: #{$size}rem !important;
  }

  .py-#{$name} {
    padding-top: #{$size}rem !important;
    padding-bottom: #{$size}rem !important;
  }

  .px-#{$name} {
    padding-left: #{$size}rem !important;
    padding-right: #{$size}rem !important;
  }

  .pt-#{$name} {
    padding-top: #{$size}rem !important;
  }

  .pl-#{$name} {
    padding-left: #{$size}rem !important;
  }

  .pr-#{$name} {
    padding-right: #{$size}rem !important;
  }

  .m-#{$name} {
    margin: #{$size}rem !important;
  }

  .my-#{$name} {
    margin-top: #{$size}rem !important;
    margin-bottom: #{$size}rem !important;
  }

  .mx-#{$name} {
    margin-left: #{$size}rem !important;
    margin-right: #{$size}rem !important;
  }

  .mt-#{$name} {
    margin-top: #{$size}rem !important;
  }

  .mb-#{$name} {
    margin-bottom: #{$size}rem !important;
  }

  .ml-#{$name} {
    margin-left: #{$size}rem !important;
  }

  .mr-#{$name} {
    margin-right: #{$size}rem !important;
  }
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.card {
  background-color: var(--white);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  backface-visibility: hidden;
}

.trip-thumbnail{
  width: 60px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.trip-image {
  position: relative;
  height: 50%;

  > label {
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    background-color: white;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 1.5rem;

    &::after{
      content: "";
    }
  }
}

button {
  font-size: 1rem;
  flex-grow: 1;
  padding: 0.5rem 0;
  background-color: var(--medium);
  color: white;
  border: 0;
  border-radius: 10rem;
  margin: 1rem 0;
  box-shadow: 1px 2px 3px -1px var(--primary);
}