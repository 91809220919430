ul.list {
  li.item {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0;

    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
      position: absolute;
      left: 0;
      opacity: 0.01;
    }
    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
      position: relative;
      padding-left: 2em;
      cursor: pointer;
      height: 1.5em;
    }

    /* checkbox aspect */
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 1.4em;
      height: 1.4em;
      border: 1px solid #aaa;
      background: #fff;
      border-radius: 0.2em;
      -webkit-transition: all 0.275s;
      transition: all 0.275s;
    }

    /* checked mark aspect */
    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
      content: "✓";
      position: absolute;
      top: 0.525em;
      left: 0.18em;
      font-size: 1.375em;
      color: var(--dark);
      line-height: 0;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
    }

    /* checked mark aspect changes */
    [type="checkbox"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0) rotate(45deg);
      transform: scale(0) rotate(45deg);
    }

    [type="checkbox"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1) rotate(0);
      transform: scale(1) rotate(0);
    }

    /* Accessibility */
    [type="checkbox"]:checked:focus + label:before,
    [type="checkbox"]:not(:checked):focus + label:before {
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 0 6px var(--tertiary);
    }

    input[type="text"] {
      flex-grow: 1;
      font-size: 18px;
      border: 0;
      border-bottom: 1px solid;
      background-color: transparent;
      border-radius: 0;
    }

    > svg {
      padding: 0.25rem 0.5rem;
    }
  }

  li.add-item{
    padding-left: 2em;
  }
}
