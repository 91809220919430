.tab-item-list{
    display: flex;
    flex-grow: 1;
    background-color: var(--grey-lighten-3);;
    
    > .tab-title{
        display: flex;
        color: var(--medium);
        padding: 1rem;
        margin-right: 0.5rem;

        &:last-child{
            margin-right: 0;
        }

        &.active{
            color: var(--dark);
            border-bottom: 4px solid var(--medium);
        }
    }
}

.tab-item-container{
    position: relative;
    overflow-x: hidden;
    padding-bottom: 75px;
    min-height: 600px;
    
    .tab-item{
        position: relative;
        width: 100%;
        top: 0;
        left: 100%;
        transition: left 200ms ease;
        height: 0;
        overflow: hidden;

        &.active{
            left: 0;
            padding: 0.5rem;
            height: auto;
        }
    }
}