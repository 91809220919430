.nav {
  display: flex;
  justify-content: space-between;
  list-style: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 1px 1px 3px var(--black);
  height: 9vh;

  .nav-item {
    padding: 1rem;
    color: var(--medium);

    > .active {
      color: var(--tertiary);
    }
  }
}
