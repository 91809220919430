ul.list {
  li.activity {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    .type {
      font-size: 1.2rem;
      width: 2.5rem;
      height: 2.5rem;
      background-color: var(--medium);
      color: var(--white);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;
    }

    .info {
      flex-grow: 1;

      .time {
        font-size: 0.8rem;
      }
    }
  }

  li.day {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    span{
      padding-right: 0.5rem;
      background-color: var(--grey-lighten-5);
    }

    &::after{
      content: "";
      position: absolute;
      width: 98%;
      top: 50%;
      right: 0;
      border: 1px solid var(--dark);
      z-index: -1;
    }
  }

  li.add-activity {
    display: flex;
    justify-content: center;
  }
}
