.loader {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  > div {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: inline-block;
    animation: slide 1s infinite;
  }
}

@for $i from 1 through 5 {
  .loader > div:nth-child(#{$i}) {
    animation-delay: (0.1s * $i);
    background: lighten(#292c35, (5 * $i));
  }
}

@keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(1.75);
  }
  100% {
    transform: scale(1);
  }
}
