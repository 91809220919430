:root {
  --medium: #69688c;
  --dark: #383d59;
  --primary: #99aabf;
  --secondary: #f2bc8d;
  --tertiary: #f29f8d;
  --grey: #9e9e9e;
  --grey-lighten-5: #fafafa;
  --grey-lighten-4: #f5f5f5;
  --grey-lighten-3: #eeeeee;
  --grey-lighten-2: #e0e0e0;
  --grey-lighten-1: #bdbdbd;
  --grey-darken-1: #757575;
  --grey-darken-2: #616161;
  --grey-darken-3: #424242;
  --grey-darken-4: #212121;
  --white: white;
  --black: black;

  --amplify-text-sm: 16px;
}
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

html {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: var(--grey-lighten-5);
}

input, .input{
  font-size: 16px;
}

#root{
  height: 100%;
}