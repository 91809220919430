form {
  display: flex;
  flex-direction: column;

  .form-group {
    display: flex;
    flex-direction: column;
    padding: 0.25rem;

    label {
      background-color: transparent;
      margin-bottom: 0.5rem;
      font-size: 1rem;
    }

    input,
    select,
    textarea {
      background-color: var(--grey-lighten-4);
      border: 1px solid var(--grey-lighten-1);
      padding: 0.5rem;
      font-size: 1rem;
      border-radius: 3px;
    }

    button.btn {
      align-self: center;
      border: 0;
      cursor: pointer;
    }
  }
}
