$breakpoints: (
  "sm": 400px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
);
$cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &.container {
    padding: 0 calc(50% - 600px);
  }

  > .row {
    width: 100%;
  }

  .col,
  .col-x {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 2rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  @each $col in $cols {
    $margin: 2rem;
    $offset: $margin - (($col/12) * $margin);

    .col-#{$col} {
      display: flex;
      flex-direction: column;
      width: calc(100% / (12 /#{$col}) - #{$offset});
      margin-right: #{$margin};

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @each $bp-name, $bp-value in $breakpoints {
    @each $col in $cols {
      $margin: 2rem;
      $offset: $margin - (($col/12) * $margin);

      .col-#{$bp-name}-#{$col} {
        display: flex;
        flex-direction: column;
        width: calc(100% / (12 /#{$col}) - #{$offset});
        margin-right: #{$margin};

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  // Mobile View
  @media (max-width: 1230px) {
    &.container {
      padding: 0 1rem;
    }
  }

  @media (max-width: 768px) {
    .col:not(.col-x) {
      width: 100%;
      flex: initial;
      margin: 0;
      margin-bottom: 1rem;
    }

    @each $col in $cols {
      .col-#{$col} {
        width: 100%;
        margin: 0;
        margin-bottom: 1rem;
      }
    }
  }

  @each $bp-name, $bp-value in $breakpoints {
    @media (max-width: $bp-value) {
      @each $col in $cols {
        .col-#{$bp-name}-#{$col} {
          width: 100%;
          margin: 0;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
