.trips-container {
  display: flex;
  flex-direction: column;
  height: 92vh;
  overflow-y: auto;
  padding: 1rem;

  .trip {
    display: flex;
    flex-direction: row;

    > .trip-info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
}
