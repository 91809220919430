.overlay {
  background-color: var(--white);
  position: fixed;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  max-height: 70%;
  padding: 1rem;
  transition: ease-in-out height 300ms;
  box-shadow: 1px 1px 3px var(--black);

  &.active {
    height: 100%;
    animation: slide-up 300ms ease-in-out;
  }

  .close {
    font-size: 2rem;
    position: absolute;
    top: -1rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 0.25rem 1rem;
    border-radius: 10px;
    box-shadow: 0px -2px 8px -6px var(--black);
  }
}

@keyframes slide-up {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}
